import React from "react";
import "./Modal.css";

const Modal = ({ isOpen, position }) => {
  if (!isOpen) return null;

  return (
    <div
      className="modal fixed z-50"
      style={{
        top: position.top,
        left: position.left
      }}
    >
      <div className="modal-arrow" />
      <img src="/img/reloj.png" alt="Logo" className="modal-img" />
      <h2 className="modal-title">Contáctanos para recargar</h2>
      <p className="modal-text">
        Puedes solicitar la información para transferir a tu cuenta en
        ayuda@kreer.mx
      </p>
    </div>
  );
};

export default Modal;
