import { Route, Routes } from "react-router-dom";
import Login from "../views/Login/Login";
import Game from "../views/Game/Game";
import Legal from "../views/Legal/Legal";
import AuthGuard from "../context/AuthGuard";
import useValidacionSesion from "../hooks/useValidacionSesion";


const AppRoutes = () => {
  useValidacionSesion();
  return (
    <Routes>
      {/* Ruta principal para juegos */}
      <Route path="/" element={<Game />} />
      {/* Ruta para login */}
      <Route
        path="/login"
        element={
          <AuthGuard redirectTo="/">
            <Login />
          </AuthGuard>
        }
      />
      <Route path="/legal" element={<Legal />} />
      {/* Cualquier ruta no encontrada */}
      <Route path="*" element={<Game />} />
    </Routes>
  );
};

export default AppRoutes;
