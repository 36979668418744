import React from 'react';
import useKart from '../hooks/useKarts';

const GrandKarts = () => {
  const { kart1Duration, kart2Duration } = useKart();

  return (
    <>
      <img
        src="/img/grand-kart1.png"
        alt="Grand Kart 1"
        className="kart1"
        style={{ animationDuration: kart1Duration }}
      />
      <img
        src="/img/grand-kart2.png"
        alt="Grand Kart 2"
        className="kart2"
        style={{ animationDuration: kart2Duration }}
      />
    </>
  );
};

export default GrandKarts;
