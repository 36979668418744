import React, { useState } from 'react';
import './Login.css';
import { useAuth } from '../../context/AuthContext';
import Swal from 'sweetalert2';
import Cohete from '../../components/Cohete';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Login = ({ onSuccess }) => {
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastName2, setLastName2] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (email && password && (isRegistering ? firstName && lastName : true)) {
      try {
        const endpoint = isRegistering ? 'clients/crear-cliente' : 'auth/login'; // Cambia entre login y registro
        const apiUrl = `${process.env.REACT_APP_API_URL}/${endpoint}`;
        console.log(`API URL: ${apiUrl}`);

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: firstName,
            lastname: lastName,
            lastname2: lastName2,
            email: email,
            password: password
          }),

        });
        console.log(JSON.stringify({
          name: firstName,
          lastname: lastName,
          lastname2: lastName2,
          email: email,
          password: password
        }));
        console.log(`Response status: ${response.status}`); // Verifica el estado de la respuesta
        console.log(`Response headers: ${JSON.stringify([...response.headers])}`); // Verifica los encabezados de la respuesta

        if (!response.ok) {
          const errorData = await response.json();
          console.log(`Error data: ${JSON.stringify(errorData)}`); // Muestra los datos del error en la consola

          let errorMessage = isRegistering
            ? 'Error al registrar usuario.'
            : 'Error al iniciar sesión.';

          if (errorData.error === 'Email already registered') {
            errorMessage = 'El correo electrónico ya está registrado.';
          } else if (errorData.error === 'Invalid credentials') {
            errorMessage = 'Credenciales inválidas.';
          }

          throw new Error(errorMessage);
        }

        const data = await response.json();

        const { token, data: user } = data;

        login(token, user);

        Swal.fire({
          title: 'Éxito',
          text: isRegistering ? 'Registro exitoso.' : 'Inicio de sesión exitoso.',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          navigate('/'); // Redirige al usuario a la página de juegos
        });

        if (typeof onSuccess === 'function') {
          onSuccess(); // Llamada segura
        }

      } catch (err) {
        console.error('Error:', err);

        let errorMessage = 'No se pudo realizar la solicitud. Por favor, inténtalo de nuevo más tarde.';

        if (err.message === 'Failed to fetch') {
          errorMessage = 'No se pudo realizar la solicitud. Verifica tu conexión a Internet o intenta más tarde.';
        }

        Swal.fire({
          title: 'Error',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    } else {
      Swal.fire({
        title: 'Advertencia',
        text: 'Por favor, completa todos los campos obligatorios.',
        icon: 'warning',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const toggleForm = () => {
    setIsRegistering(!isRegistering);
  };

  const backgroundImage = '/img/background.jpg';

  return (
    <div className="relative min-h-screen select-none">
      {/* Fondo de pantalla */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <Cohete />
      {/* Contenedor del cuadro de login */}
      <div className="relative flex items-center justify-center min-h-screen">
        <button className="absolute top-0 left-0 m-4 text-white text-2xl" onClick={() => {
          navigate('/'); // Redirige a la página de inicio después de cerrar sesión
          window.location.reload(); // Recarga la página para reflejar el cambio
          localStorage.removeItem("gameKey");
        }}> <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Atrás</button>
        <div className="w-11/12 max-w-md pb-8 shadow-lg rounded-lg bg-yellow-200 bg-opacity-40 border border-blink border-white">
          <h2 className="text-4xl font-bold mb-6 text-center text-purple-600 bg-yellow-200 bg-opacity-80 pt-8 pb-8 border-bottom rounded-md">
            {isRegistering ? 'Registrarse' : 'Iniciar sesión'}
          </h2>
          <form className="px-8" onSubmit={handleSubmit}>
            {isRegistering && (
              <>
                <div className="mb-4">
                  <label htmlFor="firstName" className="block text-lg font-bold text-gray-700">
                    Nombre(s):
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="lastName" className="block text-lg font-bold text-gray-700">
                    Apellido paterno:
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="lastName2" className="block text-lg font-bold text-gray-700">
                    Apellido materno:
                  </label>
                  <input
                    type="text"
                    id="lastName2"
                    value={lastName2}
                    onChange={(e) => setLastName2(e.target.value)}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                  />
                </div>
              </>
            )}
            <div className="mb-4">
              <label htmlFor="email" className="block text-lg font-bold text-gray-700">
                Correo electrónico:
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block text-lg font-bold text-gray-700">
                Contraseña:
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-purple-600 text-white font-semibold rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {isRegistering ? 'Registrarse' : 'Acceder'}
            </button>
            <div className="mt-4 text-center">
              <button
                type="button"
                onClick={toggleForm}
                className="text-lg font-bold text-gray-700 hover:underline focus:outline-none"
              >
                {isRegistering ? '¿Ya tienes una cuenta? Iniciar sesión' : '¿No tienes una cuenta? Registrarse'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
