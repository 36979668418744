import './SakuraPetals.css';
import usePetalos from '../hooks/usePetalos';

const GoPetalos = () => {
  const { petalos }  = usePetalos();
  return (
    <>
      {petalos.map(petalo => (
        <img
          key={petalo.id}
          src={`/img/go-petalo${petalo.petalo}.png`}
          alt={`Go Pétalo ${petalo.petalo}`}
          className="petalo"
          style={{
            top: `${petalo.top}%`,
            left: `${petalo.left}%`,
            width: `${petalo.size}%`, // Ajusta el tamaño del pétalo
          }}
        />
      ))}
    </>
  );
};

export default GoPetalos;
