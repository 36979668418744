import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Legal = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-gray-800 h-full">
      <button
        className="absolute top-0 left-0 m-4 text-white text-2xl"
        onClick={() => {
          navigate(-1); // Redirige a la página anterior
          localStorage.removeItem("gameKey");
        }}
      >
        {" "}<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />Atrás
      </button>
      <p className="p-[5rem] text-white text-2xl">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum. Aliquam non enim cursus, ornare eros a,
        molestie velit. Aenean pulvinar maximus venenatis. Aliquam lectus sem,
        condimentum aliquam arcu nec, sodales semper nunc. Proin tempus velit
        ultrices tempor sagittis. Phasellus a eleifend nibh, molestie egestas
        leo. Sed molestie interdum diam, id consectetur eros imperdiet
        pellentesque. Nulla vitae dolor vel ex finibus venenatis. Mauris ut
        elementum velit. In aliquet tristique volutpat. Phasellus at tempus
        lorem. Vestibulum venenatis dolor sollicitudin libero tincidunt varius.
        In vehicula cursus aliquet. Nunc venenatis consectetur diam. Praesent
        efficitur lorem sapien, sit amet imperdiet massa vehicula vitae. In
        risus nibh, sagittis molestie sem non, volutpat pharetra leo. Vivamus
        pulvinar, erat id hendrerit tincidunt, dolor turpis congue nisl, nec
        aliquam metus metus nec eros. Interdum et malesuada fames ac ante ipsum
        primis in faucibus. Quisque at enim sapien. Vestibulum sit amet turpis
        sit amet purus ornare ultrices non eget libero. Fusce lorem lectus,
        tempus vel dictum a, consequat et lorem. Sed vel feugiat arcu, eget
        aliquam ligula. Pellentesque interdum dui vitae est feugiat, vehicula
        ultrices urna placerat. Mauris aliquet, arcu eu aliquam vehicula, elit
        quam finibus sem, non elementum tellus mi eget lorem. Quisque enim
        augue, tincidunt id elit a, aliquet molestie nulla. Cras mauris enim,
        egestas ac ipsum vel, condimentum dictum orci. Sed id nibh venenatis,
        volutpat lorem quis, congue erat. Curabitur laoreet magna sit amet purus
        egestas ultricies. Fusce ornare orci vel elit maximus porta. Nunc
        sodales nibh urna, id dapibus dui commodo ut. Fusce finibus ligula ut
        auctor facilisis. Morbi euismod orci massa, sed commodo dui feugiat
        ultrices. Pellentesque ut velit id felis ullamcorper pretium. Morbi
        turpis augue, dignissim at nibh id, pharetra placerat erat. Nulla
        eleifend rhoncus hendrerit. Fusce iaculis maximus ipsum eget fermentum.
        Curabitur dolor magna, mattis id lectus a, semper posuere turpis. In in
        rutrum lacus. Mauris vitae neque eu velit interdum vestibulum. Phasellus
        in iaculis erat. Duis ultrices, tellus id molestie ornare, tellus dolor
        lacinia massa, in vulputate metus nibh vitae est. Aenean interdum
        viverra ex a efficitur. Duis nisl enim, mollis vitae turpis in, finibus
        tincidunt nisl. Sed placerat pretium purus, quis ultrices dolor luctus
        id. Nunc eget tortor non ex posuere aliquet a ut tortor. Vivamus cursus
        mattis feugiat. Quisque vehicula mauris eros, sit amet vestibulum diam
        fringilla a. Cras gravida odio et tortor laoreet finibus. Praesent id
        consectetur metus. semper posuere turpis. In in rutrum lacus. Mauris
        vitae neque eu velit interdum vestibulum. Phasellus in iaculis erat.
        Duis ultrices, tellus id molestie ornare, tellus dolor lacinia massa, in
        vulputate metus nibh vitae est. Aenean interdum viverra ex a efficitur.
        Duis nisl enim, mollis vitae turpis in, finibus tincidunt nisl. Sed
        placerat pretium purus, quis ultrices dolor luctus id. Nunc eget tortor
        non ex posuere aliquet a ut tortor. Vivamus cursus mattis feugiat.
        Quisque vehicula mauris eros, sit amet vestibulum diam fringilla a. Cras
        gravida odio et tortor laoreet finibus. Praesent id consectetur metus.
        semper posuere turpis. In in rutrum lacus. Mauris vitae neque eu velit
        interdum vestibulum. Phasellus in iaculis erat. Duis ultrices, tellus id
        molestie ornare, tellus dolor lacinia massa, in vulputate metus nibh
        vitae est. Aenean interdum viverra ex a efficitur. Duis nisl enim,
        mollis vitae turpis in, finibus tincidunt nisl. Sed placerat pretium
        purus, quis ultrices dolor luctus id. Nunc eget tortor non ex posuere
        aliquet a ut tortor. Vivamus cursus mattis feugiat. Quisque vehicula
        mauris eros, sit amet vestibulum diam fringilla a. Cras gravida odio et
        tortor laoreet finibus. Praesent id consectetur metus.
      </p>
    </div>
  );
};
export default Legal;
