import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gray-700 p-2">
      <Link to="/legal" className="m-4 text-white text-2xl">
        Legal
      </Link>
    </footer>
  );
};

export default Footer;
