import React from "react";
import "./GolBalon.css";

const GolBalon = () => {
  return (
    <div>
      <img
        src="/img/gol-balon.png"
        alt="Balón de Fútbol"
        className="balon"
      />
    </div>
  );
};

export default GolBalon;
