import React from "react";
import "./ModalPerfil.css";
import useUserData from "../hooks/useUserData";

const ModalPerfil = ({ isOpen, position }) => {
  const userData = useUserData(isOpen);
  if (!isOpen) return null;
  return (
    <div
      className="modal-perfil fixed bg-white text-black p-7 rounded-lg shadow-lg z-50"
      style={{
        top: position.top,
        left: position.left
      }}
    >
      <div className="modal-arrow-perfil" />
      <h1 className="text-2xl mb-3">Balance General</h1>
      <p className="text-2xl">
        $ {userData.credits}.00
      </p>
      <hr className="modal-line-perfil" />
      <div className="flex flex-column">
        <h1 className="text-2xl mb-3">Bonos</h1>
        <p className="text-2xl ml-[15.5rem]">$0.00</p>
      </div>
      <h2 className="text-xl mb-3 font-medium">Fondos no retirables</h2>
      <hr className="modal-line-perfil" />
      <div className="flex flex-column">
        <h1 className="text-2xl">Balance Total</h1>
        <p className="text-2xl ml-[9rem]">
          $ {userData.credits}.00
        </p>
      </div>
      <hr className="modal-line-perfil" />
    </div>
  );
};

export default ModalPerfil;
