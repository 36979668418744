import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Modal from "./Modal";
import ModalPerfil from "./ModalPerfil";

const NavBar = ({ setGame }) => {
  const navigate = useNavigate();
  const { user, isAuthenticated, logout } = useAuth();
  const gameKey = localStorage.getItem("gameKey");
  const [isMainModalOpen, setIsMainModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const recargaButtonRef = useRef(null);
  const openMainModal = () => {
    // Cierra el modal de perfil si está abierto
    if (isProfileModalOpen) {
      setIsProfileModalOpen(false);
    }

    if (!isMainModalOpen) {
      if (recargaButtonRef.current) {
        const buttonRect = recargaButtonRef.current.getBoundingClientRect();
        setModalPosition({
          top: buttonRect.bottom + window.scrollY,
          left: buttonRect.left + window.scrollX
        });
      }
      setIsMainModalOpen(true);
    } else {
      setIsMainModalOpen(false);
    }
  };

  const openProfileModal = () => {
    // Cierra el modal principal si está abierto
    if (isMainModalOpen) {
      setIsMainModalOpen(false);
    }

    setIsProfileModalOpen(prev => !prev);
  };

  useEffect(() => { }, [isAuthenticated]); // Dependencia en el estado de autenticación

  const handleGameClick = useCallback(
    game => {
      localStorage.setItem("gameKey", game);
      if (isAuthenticated()) {
        setGame(game);
      } else {
        navigate("/login");
      }
    },
    [isAuthenticated, navigate, setGame]
  );

  useEffect(
    () => {
      if (gameKey) {
        handleGameClick(gameKey);
      }
    },
    [gameKey, handleGameClick]
  );

  return (
    <nav className="bg-gray-800 p-2 pl-10 shadow-md flex items-center justify-between">
      {/* Botones de navegación centrados */}
      <div className="flex space-x-4 lg:space-x-8">
        <button
          className="text-white font-bold text-sm lg:text-xl tracking-wider bg-gray-700 hover:bg-purple-800 hover:bg-opacity-80 transition duration-300 px-1 lg:px-4 py-2 rounded
          border-2 border-transparent ring-2 ring-purple-500 ring-offset-2 ring-offset-gray-700
          shadow-lg shadow-purple-500/50"
          onClick={() => {
            navigate("/");
            localStorage.removeItem("gameKey");
            window.location.reload();
          }}
        >
          Inicio
        </button>
        <button
          className="text-white font-bold text-sm lg:text-xl tracking-wider bg-gray-700 hover:bg-pink-700 hover:bg-opacity-80 transition duration-300 px-1 lg:px-4 py-2 rounded
          border-2 border-transparent ring-2 ring-pink-300 ring-offset-2 ring-offset-gray-700
          shadow-lg shadow-pink-500/50"
          onClick={() => handleGameClick("go")}
        >
          Go 3
        </button>
        <button
          className="text-white font-bold text-sm lg:text-xl tracking-wider bg-gray-700 hover:bg-green-800 hover:bg-opacity-80 transition duration-300 px-1 lg:px-4 py-2 rounded
          border-2 border-transparent ring-2 ring-green-500 ring-offset-2 ring-offset-gray-700
          shadow-lg shadow-green-500/50"
          onClick={() => handleGameClick("grand")}
        >
          Grand
        </button>
        <button
          className="text-white font-bold text-sm lg:text-xl tracking-wider bg-gray-700 hover:bg-violet-900 hover:bg-opacity-80 transition duration-300 px-1 lg:px-4 py-2 rounded
          border-2 border-transparent ring-2 ring-violet-500 ring-offset-2 ring-offset-gray-700
          shadow-lg shadow-violet-500/50"
          onClick={() => handleGameClick("espacial")}
        >
          Premio Espacial
        </button>
        <button
          className="text-white font-bold text-sm lg:text-xl tracking-wider bg-gray-700 hover:bg-yellow-600 hover:bg-opacity-80 transition duration-300 px-1 lg:px-4 py-2 rounded
          border-2 border-transparent ring-2 ring-yellow-500 ring-offset-2 ring-offset-gray-700
          shadow-lg shadow-yellow-500/50"
          onClick={() => handleGameClick("gol")}
        >
          Gol Gana
        </button>
        <button
          className="text-white font-bold text-sm lg:text-xl tracking-wider bg-gray-700 hover:bg-red-700 hover:bg-opacity-85 transition duration-300 px-1 lg:px-4 py-[-10] sm:py-2 rounded
          border-2 border-transparent ring-2 ring-red-500 ring-offset-2 ring-offset-gray-700
          shadow-lg shadow-red-500/50"
          onClick={() => handleGameClick("oeste")}
        >
          Fortuna del Oeste
        </button>
        {isAuthenticated() &&
          <button
            className="text-white font-bold text-sm lg:text-xl tracking-wider bg-red-700 hover:bg-red-700 hover:bg-opacity-50 transition duration-300 px-4 py-2 rounded
            border-2 border-transparent ring-2 ring-red-800 ring-offset-2 ring-offset-gray-700
            shadow-lg shadow-red-500/50"
            onClick={() => {
              navigate("/");
              localStorage.removeItem("gameKey");
              window.location.reload();
              logout();
            }}
          >
            Cerrar sesión
          </button>}
      </div>

      {/* Logo en la esquina superior derecha */}
      <div className="flex items-center">
        {isAuthenticated() &&
          <div className="text-white font-bold text-sm lg:text-xl tracking-wider pr-5 flex items-center space-x-2">
            <button ref={recargaButtonRef} onClick={openProfileModal}>
              <img
                src="/img/perfil-icono.png"
                alt="icono"
                className="w-[3rem] h-[3rem]"
              />
            </button>
            <ModalPerfil
              isOpen={isProfileModalOpen}
              position={{ modalPosition }}
            />
            <ul className="list-none p-0 px-1 lg:px-5">
              <li>
                Hola, {user ? user.name : "Invitado"}
              </li>
              <li>
                Balance: {user ? user.credits : "N/A"}
              </li>
            </ul>
            <button
              className="text-white font-bold text-sm lg:text-xl tracking-wider bg-gray-700 hover:bg-gray-900 hover:bg-opacity-80 transition duration-300 px-4 py-2 rounded
          border-2 border-transparent ring-2 ring-gray-900 ring-offset-2 ring-offset-gray-700
          shadow-lg shadow-black-500/50"
              ref={recargaButtonRef}
              onClick={openMainModal}
            >
              Recarga
            </button>
            <Modal isOpen={isMainModalOpen} position={{ modalPosition }} />
          </div>}
        <img src="/img/DG3.gif" alt="Logo" className="h-[5.5rem] w-auto mr-4" />
      </div>
    </nav>
  );
};

export default NavBar;
