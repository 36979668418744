import { useEffect, useState } from "react";

const useUserData = (isOpen) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setUser(null);
      return;
    }

    const fetchUserData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/auth/validate-session`; // Endpoint para validación de sesión
        const token = localStorage.getItem("authToken");

        if (!token) {
          console.error("Token de autenticación no encontrado");
          return;
        }

        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        const authenticatedUser = result.data;

        if (authenticatedUser) {
          setUser({
            name: authenticatedUser.name,
            credits: authenticatedUser.credits
          });
        } else {
          console.error("Usuario no encontrado en la respuesta");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [isOpen]);

  return user || { name: "", credits: 0 }; // Devuelve valores por defecto si no hay datos
};

export default useUserData;
