import React from 'react';
import { useLocation } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import './index.css'
import Footer from './components/Footer';

const App = () => {
  const location = useLocation();

  // Determine if the current route is '/legal'
  const shouldShowFooter = location.pathname !== '/legal';

  return (
      <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
      <AppRoutes />
      </main>
      {shouldShowFooter && <Footer />}
      </div>
  );
};

export default App;