import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../components/NavBar.js";
import GoPetalos from "../../components/GoPetalos.js";
import GrandKarts from "../../components/GrandKarts.js";
import EspacialMeteoritos from "../../components/EspacialMeteoritos.js";
import GolBalon from "../../components/GolBalon.js";
import { useAuth } from "../../context/AuthContext.js";
import { useNavigate } from "react-router-dom";

import CryptoJS from 'crypto-js'


const gameUrls = {
  go:
    `${process.env.REACT_APP_API_URL_GAME}?skin=Go3&game=3&price=10&values=5-10-20-50-100-2000-5000-20000&url=ejemplo.js&function=BuyTicket`,
  grand:
    `${process.env.REACT_APP_API_URL_GAME}?skin=Grand&game=4&price=10&values=5-10-20-50-100-2000-5000-20000&url=ejemplo.js&function=BuyTicket`,
  espacial:
    `${process.env.REACT_APP_API_URL_GAME}?skin=Premio-Espacial&game=4&price=10&values=5-10-20-50-100-2000-5000-20000&url=ejemplo.js&function=BuyTicket`,
  gol:
    `${process.env.REACT_APP_API_URL_GAME}?skin=Gol-Gana&game=4&price=10&values=5-10-20-50-100-2000-5000-20000&url=ejemplo.js&function=BuyTicket`,
  oeste:
    `${process.env.REACT_APP_API_URL_GAME}?skin=Fortuna-del-Oeste&game=4&price=10&values=5-10-20-50-100-2000-5000-20000&url=ejemplo.js&function=BuyTicket`
};

const backgroundImages = {
  go: "/img/go-background.jpg",
  grand: "/img/grand-background.jpg",
  espacial: "/img/espacial-background.jpg",
  gol: "/img/gol-background.jpg",
  oeste: "/img/oeste-background.jpg"
};

const previews = {
  go: "/img/go-preview.png",
  grand: "/img/grand-preview.png",
  espacial: "/img/espacial-preview.png",
  gol: "/img/gol-preview.png",
  oeste: "/img/oeste-preview.png"
};

const videos = {
  go: "/vid/go-preview.webm",
  grand: "/vid/grand-preview.webm",
  espacial: "/vid/espacial-preview.webm",
  gol: "/vid/gol-preview.webm",
  oeste: "/vid/oeste-preview.webm"
};

const glowColors = {
  go: "from-blue-500 to-blue-700",
  grand: "from-green-500 to-green-700",
  espacial: "from-purple-500 to-purple-700",
  gol: "from-yellow-500 to-yellow-700",
  oeste: "from-red-500 to-red-700"
};

const boxShadowColors = {
  go: "rgba(255, 192, 203, 0.7)",
  grand: "rgba(34, 197, 94, 0.7)",
  espacial: "rgba(168, 85, 247, 0.7)",
  gol: "rgba(234, 179, 8, 0.7)",
  oeste: "rgba(239, 68, 68, 0.7)"
};

const Game = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const gameKey = localStorage.getItem("gameKey");

  const [game, setGame] = useState("");
  const [hoveredGame, setHoveredGame] = useState(null);

  const handleGameClick = useCallback(game => {
    localStorage.setItem("gameKey", game);
    if (isAuthenticated()) {
      setGame(game);
    } else {
      navigate("/login");
    }
  }, [isAuthenticated, navigate, setGame]);

  useEffect(() => {
    if (gameKey) {
      handleGameClick(gameKey);
    }
  }, [gameKey, handleGameClick]);

  const handleGameDemo = key => {
    if (!isAuthenticated) {
      navigate("/", { state: { from: `/game/${key}` } });
    } else {
      setGame(key);
    }
  };

  let ciphertext = CryptoJS.AES.encrypt("3", "Degestec_geUx1r^e*A5p85").toString();
  let base64 = btoa(ciphertext)

  const gameUrl = gameUrls[game] + `&dataGame=${base64}&token=${localStorage.getItem("authToken")}`;
  const backgroundImage = game ? backgroundImages[game] : "/img/background.jpg";

  return (
    <div className="relative min-h-screen bg-gray-100 overflow-hidden">
      {/* Fondo de pantalla */}
      <div
        className="absolute inset-0 bg-cover bg-center z-1"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />

      {/* Raspadito */}
      <div className="relative z-10 flex flex-col min-h-screen">
        <NavBar setGame={setGame} />
        {game !== "" &&
          <div className="flex-grow flex items-center justify-center m-[10rem] z-12">
            <iframe
              style={{
                boxShadow: `0 0 30px 15px ${boxShadowColors[game]}`
              }}
              src={gameUrl}
              title={game}
              className="w-full max-w-[800px] h-[350px] border-none bg-origin-border"
            />
          </div>}
        {/* Cuadrícula de vistas previas */}
        <div
          className={`grid gap-[5rem] p-[5rem] z-10 ${game === ""
            ? "grid-cols-2 grid-rows-3"
            : "grid-cols-5 grid-rows-1"}`}
        >
          {" "}{Object.keys(previews).map(key =>
            <div
              key={key}
              className={`relative rounded-3xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow
        bg-gradient-to-r z-11 bg-origin-border ${glowColors[key]}`}
              onMouseEnter={() => setHoveredGame(key)}
              onMouseLeave={() => setHoveredGame(null)}
              onClick={() => game !== "" && handleGameClick(key)}
              style={{
                animation: "fade-glow 3s infinite",
                boxShadow: `0 0 20px 10px ${boxShadowColors[key]}`,
                backgroundClip: "content-box, border-box"
              }}
            >
              <img
                src={previews[key]}
                alt={key}
                className="w-full h-full object-cover rounded-3xl"
              />

              {hoveredGame === key &&
                <video
                  src={videos[key]}
                  autoPlay
                  loop
                  muted
                  className="absolute inset-0 w-full h-full object-cover rounded-3xl"
                />}
              {/* Contenedor de botones */}
              <div
                className={`absolute w-full bottom-0 z-20 bg-black bg-opacity-50 rounded-b-lg p-6 flex justify-center gap-4
  ${hoveredGame === key && game === ""
                    ? "opacity-100 visible"
                    : "opacity-0 invisible"} 
  transition-opacity duration-300 ease-in-out
`}
              >
                <button
                  onClick={() => handleGameDemo(key)}
                  className="text-white font-bold text-xl tracking-wider bg-opacity-80 bg-gray-700 px-4 py-3 rounded transition-colors duration-300 ease-in-out hover:bg-blue-800 hover:bg-opacity-90 shadow-lg shadow-blue-500/50 border-2 border-transparent ring-2 ring-blue-500 ring-offset-2 ring-offset-gray-700"
                >
                  Demo
                </button>
                <button
                  onClick={() => handleGameClick(key)}
                  className="text-white bg-gray-700 tracking-wider font-bold text-xl px-4 py-3 bg-opacity-80 rounded ml-2 transition-colors duration-300 ease-in-out hover:bg-orange-700 hover:bg-opacity-80 shadow-lg shadow-orange-500/50 border-2 border-transparent ring-2 ring-orange-500 ring-offset-2 ring-offset-gray-700"
                >
                  Jugar
                </button>
              </div>
            </div>
          )}
        </div>
        {/* Animaciones */}
        {game === "oeste" &&
          <img
            src="/img/oeste-weed.png"
            alt="Tumbleweed"
            className="tumbleweed"
          />}
        {game === "go" && <GoPetalos className="z-5" />}
        {game === "grand" && <GrandKarts />}
        {game === "espacial" && <EspacialMeteoritos className="z-5" />}
        {game === "gol" && <GolBalon />}
      </div>
    </div>
  );
};

export default Game;
