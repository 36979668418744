import useEspacialMeteoritos from '../hooks/useEspacialMeteoritos';
import './EspacialMeteoritos.css'

const EspacialMeteoritos = () => {

  const { positions, coheteVisible, cohetePosition } = useEspacialMeteoritos();

  return (
    <>
      {positions.map((pos, index) => (
        <img
          key={index}
          src={`/img/espacial-meteorito${index + 1}.png`}
          alt={`Espacial Meteorito ${index + 1}`}
          className="meteorito"
          style={{
            top: pos.top,
            left: pos.left,
            transform: `rotate(${pos.rotation}deg)`,
          }}
        />
      ))}
      {coheteVisible && (
        <img
          src="/img/espacial-cohete.png"
          alt="Espacial Cohete"
          className="cohete"
          style={{
            top: cohetePosition.top,
            left: cohetePosition.left,
          }}
        />
      )}
    </>
  );
};

export default EspacialMeteoritos;
