import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const useValidacionSesion = () => {
  const { setUser, setAuthToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleReSession = async () => {
      try {
        const token = localStorage.getItem("authToken");
        setAuthToken(token)

        if (!token) {
          throw new Error("No token found");
        }

        const apiUrl = `${process.env.REACT_APP_API_URL}/auth/validate-session`;

        // Realiza la solicitud a la API
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });

        if (response.status === 401) {
          // Si el error es por falta de autorización, limpia el token y redirige al login
          localStorage.removeItem('authToken');
          navigate('/');
          return;
        }

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Procesa la respuesta JSON
        const { data } = await response.json();

        setUser(data);
      } catch (error) {
        if (error.message.includes("401")) {
          // Si el error es por falta de autorización, limpia el token y redirige al login
          localStorage.removeItem('authToken');
          navigate('/login');
        } else {
          console.error("Unexpected error:", error);
        }
      }
    };

    handleReSession();
  }, [navigate, setUser]);
};

export default useValidacionSesion;