import { useState, useEffect } from "react";

const usePetalos = () => {
  const [petalos, setPetalos] = useState([]);

  useEffect(() => {
    const addPetalo = () => {
      const randomX = Math.random() * 100; // Posición aleatoria en el ancho de la pantalla
      const randomPetalo = Math.floor(Math.random() * 5) + 1; // Selecciona uno de los 5 pétalos
      const id = Math.random().toString(36).substring(7); // ID único para cada pétalo

      setPetalos(prevPetalos => [
        ...prevPetalos,
        {
          id,
          top: 0,
          left: randomX,
          petalo: randomPetalo,
          size: Math.random() * 3 + 4 // Tamaño aleatorio entre 80% y 100%
        }
      ]);

      setTimeout(() => {
        setPetalos(prevPetalos =>
          prevPetalos.filter(petalo => petalo.id !== id)
        );
      }, 7000); // El pétalo desaparece 7 segundos después de aparecer
    };

    const intervalId = setInterval(addPetalo, Math.random() * 4000 + 1000); // Aparece cada 1 a 5 segundos

    return () => clearInterval(intervalId);
  }, []);

  return { petalos };
};

export default usePetalos;
