import { useEffect, useState } from "react";

const useKarts = () => {
  const [kart1Duration, setKart1Duration] = useState("10s");
  const [kart2Duration, setKart2Duration] = useState("10s");

  useEffect(() => {
    const getRandomDuration = () => `${Math.random() * 10 + 5}s`; // Duración entre 5s y 15s
    setKart1Duration(getRandomDuration());
    setKart2Duration(getRandomDuration());
  }, []);
  return { kart1Duration, kart2Duration };
};

export default useKarts;
