import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

const AuthGuard = ({ children, redirectTo }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (isAuthenticated()) {
    return <Navigate to={redirectTo} state={{ from: location }} />;
  }

  return children;
};

export default AuthGuard;
