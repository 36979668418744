import React from "react";
import "./Cohete.css";

const Cohete = () => {
  return (
    <div className="cohete-container">
      <img
        src="/img/espacial-cohete.png"
        alt="Espacial Cohete"
        className="cohete"
      />
    </div>
  );
};

export default Cohete;
