import { useState, useEffect } from "react";

const useEspacialMeteoritos = () => {
  const [positions, setPositions] = useState([
    { top: "20%", left: "58%", directionX: 1, directionY: 1, rotation: 0 },
    { top: "60%", left: "75%", directionX: -1, directionY: -1, rotation: 0 }
  ]);
  const [coheteVisible, setCoheteVisible] = useState(false);
  const [cohetePosition, setCohetePosition] = useState({
    top: "100%",
    left: "-10%"
  });

  useEffect(() => {
    const meteoritosIntervalId = setInterval(() => {
      setPositions(prevPositions =>
        prevPositions.map(pos => {
          let newTop = parseFloat(pos.top) + pos.directionY * 0.2; // Más lento
          let newLeft = parseFloat(pos.left) + pos.directionX * 0.2;
          let newDirectionX = pos.directionX;
          let newDirectionY = pos.directionY;

          if (newTop <= 0 || newTop >= 95) newDirectionY *= -1;
          if (newLeft <= 0 || newLeft >= 95) newDirectionX *= -1;

          return {
            ...pos,
            top: `${newTop}%`,
            left: `${newLeft}%`,
            directionX: newDirectionX,
            directionY: newDirectionY,
            rotation: pos.rotation + 0.5 // Incrementa la rotación
          };
        })
      );
    }, 16);

    const launchCohete = () => {
      setCoheteVisible(true);
      setCohetePosition({ top: "100%", left: "-10%" });

      const coheteMoveInterval = setInterval(() => {
        setCohetePosition(prevPosition => ({
          top: `${parseFloat(prevPosition.top) - 1}%`,
          left: `${parseFloat(prevPosition.left) + 1}%`
        }));
      }, 16);

      setTimeout(() => {
        clearInterval(coheteMoveInterval);
        setCoheteVisible(false);
      }, 3000); // El cohete desaparece después de 3 segundos.
    };

    const coheteLaunchIntervalId = setInterval(() => {
      launchCohete();
    }, Math.random() * 10000 + 10000); // Intervalo entre 10 y 20 segundos

    return () => {
      clearInterval(meteoritosIntervalId);
      clearInterval(coheteLaunchIntervalId);
    };
  }, []);

  return { positions, coheteVisible, cohetePosition };
};

export default useEspacialMeteoritos;
